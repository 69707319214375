export default [
	"ang",
	"az",
	"be",
	"ca@valencia",
	"cs",
	"cy",
	"da",
	"de_CH",
	"el",
	"enm",
	"et",
	"eu",
	"fil",
	"frm",
	"ga",
	"got",
	"he",
	"hi",
	"hr",
	"la",
	"lb",
	"li",
	"mk",
	"ml",
	"ms",
	"peo",
	"pr",
	"ro_MD",
	"si",
	"sl",
	"sq",
	"sr",
	"ta",
	"te",
	"tlh-qaak",
	"tokipona",
	"mt",
	"vec",
	"kmr",
	"ur",
	"as"
]